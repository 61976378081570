import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo/seo"
import "./index.css";

import importBackground from "../images/import-background.jpg"
import logisticBackground from "../images/logistic-background.jpg"
import miningBackground from "../images/mining-background.jpg"
import financialBackground from "../images/financial-background.jpg"
import energyBackground from "../images/energy-background.jpg"
import waterBackground from "../images/water-background.jpg"
import nuclearBackground from "../images/nuclear-background.jpg"
import wasteBackground from "../images/waste-background.jpg"
import constructionBackground from "../images/construction-background.jpg"
import yachtBackground from "../images/yacht-background.jpg"
import jetBackground from "../images/jet-background.jpg"
import parkBackground from "../images/park-background.jpg"
import militaryBackground from "../images/military-background.jpg"

const IndexPage = () => (
  <Layout>
    <div>

      <SEO title="Home" />

      <section id="services">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <h2>Services</h2>
              <div className="container">

                <div className="row">
                  <div className="col-sm service-box">
                    <div className="image-container">
                      <img src={importBackground} className="service-image" alt="service" />
                      <div className="image-container-centered">International import/export</div>
                    </div>
                  </div>
                  <div className="col-sm service-box">
                    <div className="image-container">
                      <img src={logisticBackground} className="service-image" alt="service" />
                      <div className="image-container-centered">Global logistics, transportation, shipping and special packaging</div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm service-box">
                    <div className="image-container">
                      <img src={miningBackground} className="service-image" alt="service" />
                      <div className="image-container-centered">Gold and mineral mining</div>
                    </div>
                  </div>
                  <div className="col-sm service-box">
                    <div className="image-container">
                      <img src={financialBackground} className="service-image" alt="service" />
                      <div className="image-container-centered">Financial services and solutions</div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm service-box">
                    <div className="image-container">
                      <img src={energyBackground} className="service-image" alt="service" />
                      <div className="image-container-centered">Energy projects</div>
                    </div>
                  </div>
                  <div className="col-sm service-box">
                    <div className="image-container">
                      <img src={waterBackground} className="service-image" alt="service" />
                      <div className="image-container-centered">Water treatment plants</div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm service-box">
                    <div className="image-container">
                      <img src={nuclearBackground} className="service-image" alt="service" />
                      <div className="image-container-centered">Nuclear plants</div>
                    </div>
                  </div>
                  <div className="col-sm service-box">
                    <div className="image-container">
                      <img src={wasteBackground} className="service-image" alt="service" />
                      <div className="image-container-centered">Waste disposal management</div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm service-box">
                    <div className="image-container">
                      <img src={constructionBackground} className="service-image" alt="service" />
                      <div className="image-container-centered">General construction and public projects</div>
                    </div>
                  </div>
                  <div className="col-sm service-box">
                    <div className="image-container">
                      <img src={yachtBackground} className="service-image" alt="service" />
                      <div className="image-container-centered">Luxury Yachts, Superyachts and Mega Yachts design and construction</div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm service-box">
                    <div className="image-container">
                      <img src={jetBackground} className="service-image" alt="service" />
                      <div className="image-container-centered">Luxury private jets</div>
                    </div>
                  </div>
                  <div className="col-sm service-box">
                    <div className="image-container">
                      <img src={parkBackground} className="service-image" alt="service" />
                      <div className="image-container-centered">World Class Amusement Rides</div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm service-box">
                    <div className="image-container">
                      <img src={militaryBackground} className="service-image" alt="service" />
                      <div className="image-container-centered">Military Supplies</div>
                    </div>
                  </div>
                  <div className="col-sm service-box">
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="contacts" className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <h2>Contacts</h2>
              <div className="container">

              <div className="row">
                  <div className="col-sm contact-box">
                  To schedule an appointment with our <i>CEO</i>, please contact his assistant: <b>PA@necsas.com</b> or by phone: <b>+1202-989-8940</b>.
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm contact-box">
                    <div><b>Jamal EB</b></div>
                    <div><u>CEO</u></div>
                    <div>Italy +39 3407750303</div>
                    <div>US +1 202 8764300</div>
                    <div>Brazil +55 (61) 4042-1488</div>
                    <div>E-mail:</div>
                    <div>jamaleb@necsas.com</div>
                    <div>jeb@necsas.com</div>
                    <div>info@necsas.com</div>
                  </div>
                  <div className="col-sm contact-box">
                    <div><b>Thomas Cindric</b></div>
                    <div><u>Senior Manager/Risk Management</u></div>
                    <div>US +1 571 2683022</div>
                    <div>E-mail: tec@necsas.com</div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
)

export default IndexPage
